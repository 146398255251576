import { notification } from 'ant-design-vue'
import axios from 'axios'
import { getToken, logout, getAuthData } from '@/helpers/authHelper' // logout

export function getBearer () {
  return 'Bearer ' + getToken()
}

export const getUUID = () => {
  return (getAuthData() && getAuthData().uuid ? getAuthData().uuid : undefined)
}

export function axiosWrapper (req, cb) {
  axios({
    ...req,
    url: process.env.VUE_APP_SERVER_URL + req.url,
    headers: {
      Authorization: getBearer()
    }
  })
    .then((response) => {
      if (cb) cb(response)
    })
    .catch((error) => {
      console.log({ error })
      let description = (error.response && error.response.status ? error.response.status + ' ' : undefined)
      let message = 'Сетевая ошибка'

      if (error.response && error.response.status === 401) {
        logout()
        return
      } else if (error.response && error.response.status === 422) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.data &&
          error.response.data.data.email[0] ===
          'The email has already been taken.'
        ) {
          message = 'Данный e-mail уже зарегистрирован'
          description = undefined
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.data &&
          error.response.data.data.url[0] ===
          'The url has already been taken.'
        ) {
          description = 'Данный url уже зарегистрирован'
        } else {
          description = 'Ошибка ' + error.response.status
        }
      } else {
        Object.keys(error.response.data.errors || {}).forEach((key) =>
          error.response.data.errors[key].forEach((text) => {
            description += text + ', '
          })
        )
      }

      notification.open({
        message,
        description
      })
    })
}

export function register (data, cb) {
  const req = {
    method: 'post',
    url: '/register',
    data
  }
  axioWrapper(req, cb)
}

export function deleteFile (data, cb) {
  axios({
    method: 'post',
    url: process.env.VUE_APP_SERVER_URL + '/delete-file',
    data: { filename: data }
  })
    .then((response) => {
      if (cb) cb(response)
    })
    .catch((error) => {
      console.log({ error })
      if (error.response && error.response.status === 401) {
        logout()
      }
    })
}
