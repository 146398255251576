<template>
  <div class="upload-container">
    <a-upload
      :fileList="fileList"
      multiple
      :before-upload="beforeUpload"
      @change="handleChange"
      class="upload-list-inline"
    >
      <div class="upload-content">
        <Button class="white-antd-button-border">
          <img src="@/assets/image/uploadFile.svg" class="upload-image" />
          <div class="upload-text">{{ name }}</div>
        </Button>
      </div>
    </a-upload>
  </div>
</template>
<script>
import Button from '@/components/common/Button.vue'
import axios from 'axios'

export default {
  props: ['name', 'file'],
  components: {
    Button
  },
  data () {
    return {
      fileList: []
    }
  },
  methods: {
    handleChange ({ fileList }) {

      if (fileList.length === 0) {
        this.fileList = []
        this.$emit('unloadFile', '')
        return
      }
    },

    beforeUpload (file) {
      if (file.size > 2000000) {
        this.$notification.open({
          message: 'Файл больше разрешеного размера'
        })
        return
      }
      this.fileList = [file]
      this.submitFile(file)
      return false
    },
    submitFile (file) {
      let formData = new FormData()

      formData.append('images[' + 0 + ']', file)

      axios
        .post(process.env.VUE_APP_SERVER_URL + '/images', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          this.$emit('unloadFile', res.data.data[0])
        })
        .catch(function () {
          console.log('FAILURE!!')
        })
    }
  }
}
</script>
<style>
.upload-container {
  min-height: 40px;
  width: 100%;
}

.upload-content {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  width: 100%;
}

.upload-image {
  margin-right: 11px;
}

.upload-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #b12021;
}

.ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.ant-upload {
  width: 100%;
  height: 40px;
}
</style>
